import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash.get'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'

import { Heading, Container, Box, Grid, FullWidth } from '../../components/UI'

import PageLayout from '../../components/PageLayout'
import PostCard from '../../components/PostCard'

const NewsPost = ({
  data: { contentfulPost: post, relatedPosts },
  pageContext,
}) => {
  const SeoImg =
    get(post, 'featuredImage.childImageSharp.sizes.src') ||
    get(post, 'featuredImage.fluid.src')

  return (
    <div>
      <SEO
        title={post.title}
        description={post.excerpt.excerpt}
        image={SeoImg}
        pathname={`post/${post.slug}`}
        article
      />
      <Layout>
        <PageLayout
          image={SeoImg}
          page={post}
          sharing
          afterContent={() =>
            relatedPosts &&
            relatedPosts.edges &&
            relatedPosts.edges.length > 1 && (
              <Box mb={-3} px={2} py={2}>
                <Container mt={2} px={0}>
                  <Heading as="h3" fontFamily="sans" mb={1}>
                    Read more
                  </Heading>
                  <Grid
                    gap={2}
                    cols={{
                      _: 1,
                      sm: 2,
                      lg: relatedPosts.edges.length > 1 ? 5 : 2,
                    }}
                  >
                    {relatedPosts.edges.map(({ node }) => (
                      <PostCard post={node} bg={'transparent'} />
                    ))}
                  </Grid>
                </Container>
              </Box>
            )
          }
        />
      </Layout>
    </div>
  )
}

export default NewsPost

export const NewsPageQuery = graphql`
  query NewsPostQuery($id: String!, $category: String!) {
    contentfulPost(id: { eq: $id }) {
      title
      slug
      contentful_id
      author {
        name
        title
        company
        bio {
          json
        }
        image {
          fixed(width: 100, height: 100) {
            ...GatsbyContentfulFixed
          }
        }
      }
      excerpt {
        excerpt
      }
      content {
        json
      }
      category {
        title
        slug
      }
      publishDate
      slider {
        images {
          fixed(width: 100, height: 100) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          fluid(maxWidth: 1150) {
            ...GatsbyContentfulFluid
          }
        }
      }
      featuredImage {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
    }
    relatedPosts: allContentfulPost(
      filter: { category: { title: { eq: $category } }, id: { ne: $id } }
    ) {
      edges {
        node {
          title
          slug
          featuredImage {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`
